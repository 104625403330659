import React from 'react';
import { withRouter } from 'react-router'
import { createBrowserHistory } from 'history';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import AppContext from '../context/app'

class Sidenav extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { };
    this.sideBarClick = this.sideBarClick.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
    this.history = createBrowserHistory();
  }

  sideBarModuleClick(e) {
    window.scroll(0,0);
    this.context.updateActiveModule(e)
    this.setState( { showNav: false } )
  }
  
  sideBarClick(e) {
    window.scroll(0,0);
    this.context.updateActiveModule(null)
    this.setState( { showNav: false } )

  }

  toggleNav(e){
    this.setState( { showNav: !this.state.showNav } )
  }

  render () {
    let homeClass = 'module flex-row'
    let customClass = 'module flex-row'
    let violationClass = 'module flex-row'

    if (!this.context.activeModule)
      homeClass = 'module active flex-row'

    if (this.context.activeModule === 'custom')
      customClass = 'module active flex-row'

    if (this.context.activeModule === 'violation')
      violationClass = 'module active flex-row'

    var imageUploadClass = classnames({
      'module flex-row': true,
      'module active flex-row': this.context.activeModule && this.context.activeModule.moduleId === 39
    })

    var notesClass = classnames({
      'module flex-row': true,
      'module active flex-row': this.context.activeModule && this.context.activeModule.moduleId === 40
    })


    return (
      <section id="sidebar">
      { 
        this.context.riskSurvey ?
          <section>
            <div className="desktop">
              <Link className='module flex-row' to="/home">
                <div className="linkStyle">
                  Dashboard
                </div>
              </Link>   

              <Link className={homeClass} to={`/riskSurvey/detail/${this.context.riskSurveyId}`} onClick={(e) => this.sideBarClick()}>
                <div className="linkStyle">
                  Report
                </div>
              </Link> 
            
              {!['fireSurvey'].includes(this.context.riskSurvey.riskSurveyTypeSystem) ?
                <div>
                  { this.context.riskSurvey.modules.map( (module) => {
                      var list = classnames({
                        'module flex-row': true,
                        'module active flex-row': this.context.activeModule && this.context.activeModule.moduleId === module.moduleId
                      });

                      return (  
                            <Link className={list} to={`/riskSurvey/${ module.config?.template ? 'template' : 'module'}/${this.context.riskSurveyId}/${module.moduleId}`} onClick={(e) => this.sideBarModuleClick(module)} key={module.moduleId}>
                              <div className="linkStyle">{module.name}</div>
                              { module.isComplete ?
                                  <span>
                                    <i className='complete-check fa fa-check-circle'></i>
                                  </span>
                                : null }

                            </Link>
                      );
                    })
                  }
                </div>
              : null }
              
              { this.context.riskSurvey.riskSurveyTypeSystem === 'fireSurvey' ?
                <div>
                  <Link className={violationClass} to={`/riskSurvey/violation/${this.context.riskSurveyId}`} onClick={(e) => this.sideBarModuleClick('violation')}>
                    <div className="linkStyle">
                      Begin Survey
                    </div>
                  </Link> 

                  <Link className={notesClass} to={`/riskSurvey/module/${this.context.riskSurveyId}/40`} onClick={(e) => this.sideBarModuleClick({moduleId: 40})}>
                    <div className="linkStyle">
                      Recommendations/Notes
                    </div>
                  </Link>

                  <Link className={imageUploadClass} to={`/riskSurvey/module/${this.context.riskSurveyId}/39`} onClick={(e) => this.sideBarModuleClick({moduleId: 39})}>
                    <div className="linkStyle">
                      Image/Upload
                    </div>
                  </Link> 
                </div>
              : null }


            </div>

            <div className="tablet">
              <div>
                { !this.state.showNav ?
                  <i className="fa fa-bars showNav-bar" onClick={this.toggleNav}></i>
                :
                  <i className="fa fa-minus showNav-bar" onClick={this.toggleNav}></i>
                }
              </div>

              { this.state.showNav ?
              <div className="mobile-nav">
               <Link className='module flex-row' to="/home">
                <div className="linkStyle">
                  Dashboard
                </div>
              </Link>   

              <Link className={homeClass} to={`/riskSurvey/detail/${this.context.riskSurveyId}`} onClick={(e) => this.sideBarClick()}>
                <div className="linkStyle">
                  Report
                </div>
              </Link> 
            
              {!['fireSurvey'].includes(this.context.riskSurvey.riskSurveyTypeSystem) ?
                <div>
                  { this.context.riskSurvey.modules.map( (module) => {
                      var list = classnames({
                        'module flex-row': true,
                        'module active flex-row': this.context.activeModule && this.context.activeModule.moduleId === module.moduleId
                      });

                      return (  
                        <Link className={list} to={`/riskSurvey/${ module.config?.template ? 'template' : 'module'}/${this.context.riskSurveyId}/${module.moduleId}`} onClick={(e) => this.sideBarModuleClick(module)} key={module.moduleId}>
                          <div className="linkStyle">{module.name}</div>
                          { module.isComplete ?
                            <span>
                              <i className='complete-check fa fa-check-circle'></i>
                            </span>
                          : null }
                        </Link>
                      );
                    })
                  }
                </div>
              : null }

              { this.context.riskSurvey.riskSurveyTypeSystem === 'fireSurvey' ?
              <div>
                <Link className={violationClass} to={`/riskSurvey/violation/${this.context.riskSurveyId}`} onClick={(e) => this.sideBarClick()}>
                  <div className="linkStyle">
                    Begin Survey
                  </div>
                </Link> 

                <Link className={notesClass} to={`/riskSurvey/module/${this.context.riskSurveyId}/40`} onClick={(e) => this.sideBarModuleClick({moduleId: 40})}>
                  <div className="linkStyle">
                    Recommendations/Notes
                  </div>
                </Link>

                <Link className={imageUploadClass} to={`/riskSurvey/module/${this.context.riskSurveyId}/39`} onClick={(e) => this.sideBarModuleClick({moduleId: 39})}>
                  <div className="linkStyle">
                    Image/Upload
                  </div>
                </Link> 
              </div>
              : null }

              </div>
            : null }
            </div>
          </section>
        :
          null
        }
      </section>
    )
  }
}

export default withRouter(Sidenav)
